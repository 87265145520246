import React, { useState, useEffect } from "react";
import Hero from "../components/Hero";
import { Button } from "react-bootstrap";
import consulting from "../assets/images/Consulting.png";
import marketing from "../assets/images/Marketing.png";
import technology from "../assets/images/Technology.png";
import marriott from "../assets/images/Marriott-emblem.jpeg";
import nbc from "../assets/images/NBC-Universal.png";
import carnival from "../assets/images/carnival-corporation-logo.jpeg";
import disney from "../assets/images/Walt_Disney_World_Resort_logo.png";
import magic from "../assets/images/Orlando-Magic.png";

function Index() {
  const isBrowser = typeof window !== "undefined";

  const [dimensions, setDimensions] = useState({
    width: isBrowser ? window.innerWidth : "1024px",
  });

  useEffect(() => {
    if (isBrowser) {
      function handleResize() {
        setDimensions({
          width: window.innerWidth,
        });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  function calculateVideoWidth(screenWidth) {
    if (screenWidth > 1398) {
      return screenWidth;
    } else {
      return "auto";
    }
  }

  function calculateVideoHeight(screenWidth) {
    if (screenWidth > 1398) {
      return "auto";
    } else if (1398 >= screenWidth && screenWidth >= 1024) {
      return "286px";
    } else if (1023 >= screenWidth && screenWidth >= 768) {
      return "270px";
    } else {
      return "202px";
    }
  }

  const homeHeroContent = (
    <h1 className="home-hero-title">
      Helping Forward-Thinking Industry Leaders
      <br className="hero-desktop"></br>
      <span> </span>
      Achieve Accelerated Growth
    </h1>
  );

  return (
    isBrowser && (
      <>
        <section className="promo">
          <div className="video-container">
            <video
              autoPlay
              loop
              muted
              className="video"
              style={{
                width: calculateVideoWidth(dimensions.width),
                height: calculateVideoHeight(dimensions.width) 
              }}
            >
              <source
                src="https://lmsonline.com/wp-content/uploads/2018/05/home_cropped_slowed_55-1.mp4"
                type="video/mp4"
              />
              <source
                src="https://lmsonline.com/wp-content/uploads/2018/05/home_cropped_slowed_55-1.webm"
                type="video/webm"
              />
              <source
                src="https://lmsonline.com/wp-content/uploads/2018/05/home_cropped_slowed_55-1.ogv"
                type="video/ogg"
              />
            </video>
          </div>
          <Hero content={homeHeroContent} background="home-hero" />
        </section>

        <div className="light-blue-section">
          <div className="section-container">
            <h2>We Offer Innovative Solutions Through:</h2>
            <div className="three-column d-md-flex flex-wrap">
              <div className="col-md-4">
                <div className="text-item">
                  <img src={consulting} alt=""/>
                  <h3>Consulting</h3>
                  <p>Transform your customer experience from what it is to what it could be by boosting operations with creative ideation and business analytics.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-item">
                  <img src={marketing} alt=""/>
                  <h3>Marketing</h3>
                  <p>Convert prospects into your most loyal customers and brand ambassadors by redefining and enhancing your customer journey.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-item">
                  <img src={technology} alt=""/>
                  <h3>Technology</h3>
                  <p>Know and engage your customers on a deeper level by leveraging customer-driven microservices and tech solutions.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button variant="what-we-do btn-lg" href="https://lmsonline.com/what-we-do/" target="_blank" rel="noreferrer" style={{color: '#fff'}}>What We Do </Button>
          </div>
        </div>

        <div className="section-container">
          <div class="text-box1">
            <p>LMS is committed to providing end-to-end marketing solutions and dependable, extensible technology to support long-term growth and success. We have evolved into an organization made up of strategic, authentic, and passionate innovators who support Fortune 100 global brands. We know what it takes to help businesses accelerate in this competitive marketplace, and our goal is to provide the tools and the team to help you build a stronger future in today's global economy.</p>
          </div>
        </div>

        <div class="blue-panel">
          <div class="section-container">
            <h2>Our Partners</h2> 
          </div>
        </div>

        <div className="section-container">
          <div className="logos">
            <img src={marriott} alt="marriott logo" />
            <img src={nbc} alt="nbc/universal logo" />
            <img src={carnival} alt="carnival corporation logo" />
            <img src={disney} alt="walt disney world resort logo" />
            <img src={magic} alt="orlando magic logo" />
          </div>
        </div>
      </>
    )
  );
}

export default Index;
