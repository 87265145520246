import React from "react";
import { Jumbotron, Container } from "react-bootstrap";

function Hero(props) {
  return (
    <Jumbotron fluid className={props.background ? `hero-container ${props.background}` : "hero"}>
      <Container className="hero-content-container">
        {props.content}
      </Container>
    </Jumbotron>
  );
}

export default Hero;
